.contact-container {
    min-height: calc(100vh - 64px);
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #b5e6ff 0%, #fff2f2 100%);
}

@media (max-width: 768px) {
    .contact-container {
        padding: 20px;
    }
} 